* {
  box-sizing: border-box;
}

.App {
  height: 100vw;
  width: 100vw;
  margin: -8px;
}

.sr-only {
  background-color: white;
  color: #474b4f;
  display: flex;
  justify-content: center;
  /* padding: 300px; */
}

#loginTextDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: grey;
  width: fit-content;
  width: 100vw;
  
  
}

.motto {
  color: whitesmoke;
}
.logo {
  margin-top: 10px;
  height: 170px;
}

.login-text {
  display: flex;
  font-size: larger;
  margin-bottom: 10px;
  color: whitesmoke;
  margin-left: 655px;
  
}

.coder-text {
  margin-left: 7px;
  padding-bottom: -3px;
  margin-top: -10px;
  color: #86c232;
  font-size: 50px;
}

.text-box {
  background-color: whitesmoke;
  width: 400px;
  height: 35px;
  color: #6b6e70;
  border-radius: 5px;
  font-size: larger;
  border: #222629;
}

.login-button {
  background-color: #61892f;
  color: whitesmoke;
  padding: 10px;
  width: 408px;
  margin-top: 10px;
  border-radius: 20px;
  font-size: larger;
  border: 1px solid #474b4f;
}

.login-button:hover {
  background-color: #86c232;
}

.loginStatus {
  color: #222629;
  font-size: 14px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 97vw;
  justify-content: end;
  margin-top: 10px;
}



.login {
  font-weight: lighter;

  height: 100vw;
  width: 100vw;
  text-align: center;
  margin-top: 0px;
  background-color: #222629;
  margin: -8px;
  margin-bottom: -10px;
}
#missionStatement{
  width: 800px;
  
}
