/* .back-logo {
  height: 400px;
  background-color: #222629;
  color: white;
} */

.form-items {
 display: flex;
 
 flex-wrap: wrap;
 justify-content: space-between;
 
}

.update-email {
  border: 2px solid #e3e8ec;
  padding: 10px;
  
  
}

.add-photo {
  align-content: center;
  border: 2px solid #e3e8ec;
  padding: 10px;
}

.about-me {
  border: 2px solid #e3e8ec;
  padding: 10px;
}

.add-style {
  display: flex;
  
  justify-content: center;
  border: 2px solid #e3e8ec;
  width: 100vw;
  padding: 10px;
  margin-top: 10px;
  
}
.importantText{
  color: red;
}
