* {
  font-family: "Russo One", sans-serif;
  font-weight: lighter;
}

.logo {
  width: 50px;
  height: 50px;
  color: #86c232;
}

.NavbarItems {
  display: flex;
  background: #222629;
  height: 80px;

  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  width: 100vw;
  margin-left: -8px;
  margin-top: -8px;
}

.navbar-logo {
  color: #61892f;
}

.menu-items {
  display: flex;
  justify-content: space-evenly;

  list-style: none;
  text-align: center;
  width: 70vw;

  margin-right: 2rem;
}

.home {
  color: white;
  text-decoration: none;
  padding: 0.5rem, 1rem;
}

.home:hover {
  color: #86c232;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.dashboard {
  color: white;
  text-decoration: none;
  padding: 0.5rem, 1rem;
}

.dashboard:hover {
  border-radius: 4px;
  transition: all 0.2s ease-out;
  color: #86c232;
}
