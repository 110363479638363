#messagesDiv {
  display: flex;
  justify-content: center;
  
  
  
  border-radius: 5px;
}

.inputText {
  justify-content: center;
  width: 70%;
  height: 15px;
  
  border-radius: 5px;
}

#messageInput{
  background-color: black;
  color: green;
  box-shadow:2px 5px 10px 1px green;
  width: 200px;
  height: 100px;
  
}

#messageBoxText ,h2{
  display: flex;
  justify-content: center;
  
}
