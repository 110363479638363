.page {
  background-color: white;
}
.edit-button {
  text-decoration: none;
  color: #222629;
  font-size: 14px;
  display: inline-block;
  position: absolute;
  margin-top: -25px;
}

.profile-pic {
  
border-radius: 50%;
width: 150px;
box-shadow:5px 8px 15px 2px  grey;
  
  
  
}
#profilePicDiv{
  display: flex;
  justify-content: center;
  
  
  
}

.about-me-dashboard {
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 25px;
  margin-top: 5px;
  border: 1px solid #6b6e70;
  border-radius: 10px;
  flex-direction: column;
  font-size: 12px;
  
  
  
}

.about-title {
  color: #222629;
  
  margin-bottom: 5px;
}

.message {
  height: 250px;
}
